import * as React from "react"

import { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

// Components
import { Section } from "../StyledComponents/layoutComponentStyles"
import CasesGalleryItem from "./CaseStudiesGalleryItem"

// Animation
import { galleryScrub } from "../../animations/pages/homeAnim"

/*









*/

const CasesGalleryAll: React.FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityProjects(sort: { order: ASC, fields: order }) {
        edges {
          node {
            title
            comingSoon
            slug {
              current
            }
            tagline
            thumbnail {
              altText
              image {
                asset {
                  fluid(maxWidth: 1000) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  // Effects
  useEffect(() => {
    const thumbnails = Array.from(document.querySelectorAll(".o-cases-gallery-thumb__asset"))
    thumbnails.forEach(thumbnail => {
      return galleryScrub(thumbnail)
    })
  }, [])

  return (
    <Section>
      <div className='o-cases-gallery'>
        {data.allSanityProjects.edges.map(({ node: project }: any) => (
          <CasesGalleryItem project={project} key={project.slug.current} />
        ))}
      </div>
    </Section>
  )
}

export default CasesGalleryAll
