import * as React from "react"

import { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

// Styles

// Components
import { Container, Section } from "../StyledComponents/layoutComponentStyles"
import CasesGalleryItem from "./CaseStudiesGalleryItem"

// Animation
import { galleryScrub } from "../../animations/pages/homeAnim"

/*









*/

const CasesGalleryBranding: React.FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityProjects(
        sort: { order: ASC, fields: order }
        filter: { projectCategories: { elemMatch: { title: { eq: "Branding" } } } }
      ) {
        edges {
          node {
            title
            comingSoon
            slug {
              current
            }
            tagline
            thumbnail {
              altText
              image {
                asset {
                  fluid(maxWidth: 1000) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    const thumbnails = Array.from(document.querySelectorAll(".o-cases-gallery-thumb__asset"))
    thumbnails.forEach(thumbnail => {
      return galleryScrub(thumbnail)
    })
  }, [])

  return (
    <Section>
      <Container fourteenClm>
        <div className='o-cases-gallery'>
          {data.allSanityProjects.edges.map(({ node: project }: any) => (
            <CasesGalleryItem project={project} key={project.slug.current} />
          ))}
        </div>
      </Container>
    </Section>
  )
}

export default CasesGalleryBranding
