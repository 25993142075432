import * as React from "react"
import { useState, useEffect, useContext } from "react"
import ReactGA from "react-ga"

// Context
import ScrollSafeContext from "../context/scrollSafeContext"

// Componenets
import SEO from "../components/seo"
import WorkHero from "../components/WorkHero"
import WorkCasesGallery from "../components/WorkCasesGallery"

// Animations
import { ScrollTrigger } from "gsap/ScrollTrigger"

// Interfaces
interface WorkPageProps {}

// SEO
const seo = {
  title: "Work",
  description: "We design and build compelling websites and identities for brands and businesses that want to do good in the world.",
  keywords: [
    "digital design studio",
    "web design shrewsbury",
    "web design midlands",
    "freelance web developer",
    "brand design uk",
    "freelance brand design",
  ],
}

// Helper functions
export const checkMedia = () => {
  if (window.innerWidth > 1024) {
    return "10rem"
  } else if (window.innerWidth > 480) {
    return "33rem"
  } else {
    return "55rem"
  }
}

/*









*/

const WorkPage: React.FunctionComponent<WorkPageProps> = () => {
  // Context
  const { scrollSafe, setScrollSafe } = useContext(ScrollSafeContext)

  // States
  const [workCategory, setWorkCategory] = useState<string>("all")

  // Effects
  useEffect(() => {
    setTimeout(() => {
      document.body.style.height = `${
        document.getElementById("scroll-container")!.getBoundingClientRect().height + window.innerHeight
      }px`
    }, 900)
  }, [workCategory])

  useEffect(() => {
    setTimeout(() => {
      ScrollTrigger.refresh()
      setScrollSafe?.(true)
    }, 600)

    ReactGA.pageview(window.location.pathname + window.location.search)

    return () => {
      setScrollSafe?.(false)
    }
  }, [])

  return (
    <>
      <SEO title={seo.title} description={seo.description} keywords={seo.keywords} />
      {scrollSafe && (
        <>
          <WorkHero workCategory={workCategory} setWorkCategory={setWorkCategory} />
          <WorkCasesGallery workCategory={workCategory} />
        </>
      )}
    </>
  )
}
export default WorkPage
