import * as React from "react"
import { useRef, useEffect, useContext } from "react"
import ReactGA from "react-ga"

// Context
import CursorContext from "../../context/cursorContext"

// Components
import ScrollPrompt from "../ScrollPrompt"
import { Section, Container } from "../StyledComponents/layoutComponentStyles"
import { $desktop, $tablet, $mobile } from "../../utils/breakpoints"

// Animations
import { scrollPrompt, slideTitleParent, slideTitleChild, verticalTagline } from "../../animations/globalVariants"
import { motion } from "framer-motion"

// Interfaces
interface WorkHeroProps {
  workCategory: string
  setWorkCategory: React.Dispatch<React.SetStateAction<string>>
}

interface WorkHeroTitleProps {
  workCategory: string
  setWorkCategory: React.Dispatch<React.SetStateAction<string>>
}

/*









*/

const WorkHero: React.FunctionComponent<WorkHeroProps> = ({ workCategory, setWorkCategory }) => {
  return (
    <div id='work-hero'>
      <Section fullHeight alignCenter>
        <Container sixteenClm fullHeight>
          <div className='o-hero-title-wrapper -responsive'>
            {$desktop && <WorkHeroTitleDesktop workCategory={workCategory} setWorkCategory={setWorkCategory} />}
            {$tablet && <WorkHeroTitleTablet workCategory={workCategory} setWorkCategory={setWorkCategory} />}
            {$mobile && <WorkHeroTitleMobile workCategory={workCategory} setWorkCategory={setWorkCategory} />}
          </div>
          <div className='o-vertical-tagline'>
            <motion.span initial='initial' animate='animate' exit='exit' variants={verticalTagline}>
              See for yourself
            </motion.span>
          </div>
          {!$mobile ? <ScrollPrompt variants={scrollPrompt} /> : null}
        </Container>
      </Section>
    </div>
  )
}

export default WorkHero

export const WorkHeroTitleDesktop: React.FunctionComponent<WorkHeroTitleProps> = ({ workCategory, setWorkCategory }) => {
  // Context
  const { setCursorState } = useContext(CursorContext)

  // Refs
  const digitalRef = useRef<HTMLDivElement>(null)
  const brandingRef = useRef<HTMLDivElement>(null)
  const digitalText = useRef<HTMLSpanElement>(null)
  const brandingText = useRef<HTMLSpanElement>(null)

  // Effects
  useEffect(() => {
    if (workCategory === "digital") {
      digitalText.current?.classList.add("is-current")
      brandingText.current?.classList.contains("is-current") ? brandingText.current?.classList.remove("is-current") : null
    }

    if (workCategory === "branding") {
      brandingText.current?.classList.add("is-current")
      digitalText.current?.classList.contains("is-current") ? digitalText.current?.classList.remove("is-current") : null
    }

    if (workCategory === "all") {
      brandingText.current?.classList.contains("is-current") ? brandingText.current?.classList.remove("is-current") : null
      digitalText.current?.classList.contains("is-current") ? digitalText.current?.classList.remove("is-current") : null
    }
  }, [workCategory])

  useEffect(() => {
    // add when mounted

    const handleClick = (e: any) => {
      if (digitalRef.current?.contains(e.target)) {
        setWorkCategory("digital")
        ReactGA.event({ category: "Navigation", action: "Clicked work sort button (:digital)", label: "Work Sort Button" })
      } else if (brandingRef.current?.contains(e.target)) {
        setWorkCategory("branding")
        ReactGA.event({ category: "Navigation", action: "Clicked work sort button (:branding)", label: "Work Sort Button" })
      } else {
        setWorkCategory("all")
        ReactGA.event({ category: "Navigation", action: "Clicked work sort button (:all)", label: "Work Sort Button" })
      }
    }

    document.getElementById("work-hero")?.addEventListener("mousedown", handleClick, false)

    return () => {
      document.getElementById("work-hero")?.removeEventListener("mousedown", handleClick, false)
    }
  }, [setWorkCategory])

  return (
    <motion.div variants={slideTitleParent} initial='initial' animate='animate' exit='exit' className='o-hero-title -work'>
      <div className='u-overflow-reveal'>
        <motion.h3 variants={slideTitleChild}>We proudly design, develop and deliver </motion.h3>
      </div>
      <div className='u-overflow-reveal'>
        <motion.h3 variants={slideTitleChild}>
          <div className='o-category-btn' ref={digitalRef}>
            <span
              ref={digitalText}
              className='o-category-btn__text'
              onMouseEnter={() => setCursorState?.("is-link")}
              onMouseLeave={() => setCursorState?.("")}
              role='button'
              tabIndex={0}
            >
              digital products&nbsp;
            </span>
          </div>
          and&nbsp;
          <div className='o-category-btn' ref={brandingRef}>
            <span
              className='o-category-btn__text'
              ref={brandingText}
              onMouseEnter={() => setCursorState?.("is-link")}
              onMouseLeave={() => setCursorState?.("")}
              role='button'
              tabIndex={0}
            >
              brand identities
            </span>
          </div>
        </motion.h3>
      </div>
    </motion.div>
  )
}

export const WorkHeroTitleTablet: React.FunctionComponent<WorkHeroTitleProps> = ({ workCategory, setWorkCategory }) => {
  // Refs
  const digitalRef = useRef<HTMLDivElement>(null)
  const brandingRef = useRef<HTMLDivElement>(null)
  const digitalText = useRef<HTMLSpanElement>(null)
  const brandingText = useRef<HTMLSpanElement>(null)

  // Effects

  useEffect(() => {
    if (workCategory === "digital") {
      digitalText.current?.classList.add("is-current")
      brandingText.current?.classList.contains("is-current") ? brandingText.current?.classList.remove("is-current") : null
    }

    if (workCategory === "branding") {
      brandingText.current?.classList.add("is-current")
      digitalText.current?.classList.contains("is-current") ? digitalText.current?.classList.remove("is-current") : null
    }

    if (workCategory === "all") {
      brandingText.current?.classList.contains("is-current") ? brandingText.current?.classList.remove("is-current") : null
      digitalText.current?.classList.contains("is-current") ? digitalText.current?.classList.remove("is-current") : null
    }
  }, [workCategory])

  useEffect(() => {
    let dragging = false

    const handleTouch = (e: any) => {
      if (digitalRef.current?.contains(e.target)) {
        setWorkCategory("digital")
        ReactGA.event({ category: "Navigation", action: "Tapped work sort button (:digital)", label: "Work Sort Button" })
      } else if (brandingRef.current?.contains(e.target)) {
        setWorkCategory("branding")
        ReactGA.event({ category: "Navigation", action: "Tapped work sort button (:branding)", label: "Work Sort Button" })
      } else {
        setWorkCategory("all")
        ReactGA.event({ category: "Navigation", action: "Tapped work sort button (:all)", label: "Work Sort Button" })
      }
    }

    const process_touchmove = () => {
      // console.log("touchmove")
      dragging = true
    }

    const process_touchend = (e: any) => {
      // console.log("touchend")
      if (!dragging) {
        handleTouch(e)
      } else {
        return
      }
    }

    const process_touchstart = () => {
      // console.log("touchstart")
      dragging = false
    }

    document.getElementById("work-hero")?.addEventListener("touchmove", process_touchmove, false)
    document.getElementById("work-hero")?.addEventListener("touchend", process_touchend, false)
    document.getElementById("work-hero")?.addEventListener("touchstart", process_touchstart, false)

    return () => {
      document.getElementById("work-hero")?.removeEventListener("touchmove", process_touchmove, false)
      document.getElementById("work-hero")?.removeEventListener("touchend", process_touchend, false)
      document.getElementById("work-hero")?.removeEventListener("touchstart", process_touchstart, false)
    }
  }, [setWorkCategory])

  return (
    <motion.div variants={slideTitleParent} initial='initial' animate='animate' exit='exit' className='o-hero-title -work'>
      <div className='u-overflow-reveal'>
        <motion.h3 variants={slideTitleChild}>We proudly design, develop and</motion.h3>
      </div>
      <div className='u-overflow-reveal'>
        <motion.h3 variants={slideTitleChild}>
          deliver
          <div className='o-category-btn' ref={digitalRef}>
            <span ref={digitalText} className='o-category-btn__text'>
              &nbsp;digital products&nbsp;
            </span>
          </div>
          and
        </motion.h3>
      </div>
      <div className='u-overflow-reveal'>
        <motion.h3 variants={slideTitleChild}>
          <div className='o-category-btn' ref={brandingRef}>
            <span className='o-category-btn__text' ref={brandingText}>
              brand identities
            </span>
          </div>
        </motion.h3>
      </div>
    </motion.div>
  )
}

export const WorkHeroTitleMobile: React.FunctionComponent<WorkHeroTitleProps> = ({ workCategory, setWorkCategory }) => {
  // Refs
  const digitalRef = useRef<HTMLDivElement>(null)
  const brandingRef = useRef<HTMLDivElement>(null)
  const digitalText = useRef<HTMLSpanElement>(null)
  const brandingText = useRef<HTMLSpanElement>(null)

  // Effects
  useEffect(() => {
    if (workCategory === "digital") {
      digitalText.current?.classList.add("is-current")
      brandingText.current?.classList.contains("is-current") ? brandingText.current?.classList.remove("is-current") : null
    }

    if (workCategory === "branding") {
      brandingText.current?.classList.add("is-current")
      digitalText.current?.classList.contains("is-current") ? digitalText.current?.classList.remove("is-current") : null
    }

    if (workCategory === "all") {
      brandingText.current?.classList.contains("is-current") ? brandingText.current?.classList.remove("is-current") : null
      digitalText.current?.classList.contains("is-current") ? digitalText.current?.classList.remove("is-current") : null
    }
  }, [workCategory])

  useEffect(() => {
    let dragging = false

    const handleTouch = (e: any) => {
      if (digitalRef.current?.contains(e.target)) {
        setWorkCategory("digital")
        ReactGA.event({ category: "Navigation", action: "Tapped work sort button (:digital)", label: "Work Sort Button" })
      } else if (brandingRef.current?.contains(e.target)) {
        setWorkCategory("branding")
        ReactGA.event({ category: "Navigation", action: "Tapped work sort button (:branding)", label: "Work Sort Button" })
      } else {
        setWorkCategory("all")
        ReactGA.event({ category: "Navigation", action: "Tapped work sort button (:allTapped)", label: "Work Sort Button" })
      }
    }

    const process_touchmove = () => {
      // console.log("touchmove")
      dragging = true
    }

    const process_touchend = (e: any) => {
      // console.log("touchend")
      if (!dragging) {
        handleTouch(e)
      } else {
        return
      }
    }

    const process_touchstart = () => {
      // console.log("touchstart")
      dragging = false
    }

    document.getElementById("work-hero")?.addEventListener("touchmove", process_touchmove, false)
    document.getElementById("work-hero")?.addEventListener("touchend", process_touchend, false)
    document.getElementById("work-hero")?.addEventListener("touchstart", process_touchstart, false)

    return () => {
      document.getElementById("work-hero")?.removeEventListener("touchmove", process_touchmove, false)
      document.getElementById("work-hero")?.removeEventListener("touchend", process_touchend, false)
      document.getElementById("work-hero")?.removeEventListener("touchstart", process_touchstart, false)
    }
  }, [setWorkCategory])

  return (
    <motion.div variants={slideTitleParent} initial='initial' animate='animate' exit='exit' className='o-hero-title -work'>
      <div className='u-overflow-reveal'>
        <motion.h3 variants={slideTitleChild}>We proudly design,</motion.h3>
      </div>

      <div className='u-overflow-reveal'>
        <motion.h3 variants={slideTitleChild}>develop and deliver</motion.h3>
      </div>

      <div className='u-overflow-reveal'>
        <motion.h3 variants={slideTitleChild}>
          <div className='o-category-btn' ref={digitalRef}>
            <span ref={digitalText} className='o-category-btn__text'>
              digital products&nbsp;
            </span>
          </div>
        </motion.h3>
      </div>

      <div className='u-overflow-reveal' style={{ display: "block" }}>
        <motion.h3 variants={slideTitleChild}>and</motion.h3>
      </div>

      <div className='u-overflow-reveal'>
        <motion.h3 variants={slideTitleChild}>
          <div className='o-category-btn' ref={brandingRef}>
            <span className='o-category-btn__text' ref={brandingText}>
              brand identities
            </span>
          </div>
        </motion.h3>
      </div>
    </motion.div>
  )
}
