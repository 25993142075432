import * as React from "react"
import { useState, useEffect } from "react"

// Components
import CasesGalleryAll from "../CaseStudies/CaseStudiesGalleryAll"
import CasesGalleryBranding from "../CaseStudies/CaseStudiesGalleryBranding"
import CasesGalleryDigital from "../CaseStudies/CaseStudiesGalleryDigital"

// Animations
import { easeOutExpo, easeInExpo, $speedP2, $speedP1 } from "../../animations/animUtils"
import { motion, AnimatePresence } from "framer-motion"
import { $desktop, $tablet } from "../../utils/breakpoints"
import { Container, Section } from "../StyledComponents/layoutComponentStyles"

// Helper Functions
export const checkMedia = () => {
  if ($desktop) {
    return "-10rem"
  } else if ($tablet) {
    return "-33rem"
  } else {
    return "-55rem"
  }
}

interface WorkCasesGalleryProps {
  workCategory: string
}

/*









*/

const WorkCasesGallery: React.FunctionComponent<WorkCasesGalleryProps> = ({ workCategory }) => {
  const [safeToAnimate, setSafeToAnimate] = useState(false)

  const galleryVars = {
    initial: { opacity: 0, marginTop: "0rem" },
    animate: {
      marginTop: checkMedia(),
      opacity: 1,
      transition: {
        duration: $speedP2,
        ease: easeOutExpo,
        delay: safeToAnimate ? 0.3 : 1.2,
      },
    },
    exit: {
      marginTop: "0rem",
      opacity: 0,
      transition: {
        duration: $speedP2,
        ease: easeInExpo,
      },
    },
  }

  useEffect(() => {
    setTimeout(() => {
      setSafeToAnimate(true)
    }, 2000)
  }, [])

  return (
    <motion.div initial='initial' animate='animate' exit={{ opacity: 0, transition: { duration: $speedP1, ease: "linear" } }}>
      <Section>
        <Container sixteenClm={!$desktop} fourteenClm={$desktop || undefined}>
          <AnimatePresence exitBeforeEnter>
            {workCategory === "digital" ? (
              <motion.div key='casesGalleryDigital' initial='initial' animate='animate' exit='exit' variants={galleryVars}>
                <CasesGalleryDigital />
              </motion.div>
            ) : workCategory === "branding" ? (
              <motion.div key='casesGalleryBranding' initial='initial' animate='animate' exit='exit' variants={galleryVars}>
                <CasesGalleryBranding />
              </motion.div>
            ) : (
              <motion.div key='casesGalleryAll' initial='initial' animate='animate' exit='exit' variants={galleryVars}>
                <CasesGalleryAll />
              </motion.div>
            )}
          </AnimatePresence>
        </Container>
      </Section>
    </motion.div>
  )
}

export default WorkCasesGallery
